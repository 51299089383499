@import '../styles/settings.scss';

section#contact {
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;
    position: relative;

    p, label {
        color: $white;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 1px;
    }

    p {
        position: relative;
        width: 70%;
        margin-top: 3rem;   
    }

    h3 {
        position: relative;
        font-family: $signature;
        font-size: 3rem;
        font-weight: 500;
        font-style: normal;
        line-height: 2;
        color: $white;
        margin-top: 5rem;
        -webkit-transform: rotate(-10deg);
        -khtml-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        transform: rotate(-10deg);
        left: 7%;
    }

    #contact-msg, #contact-form, #dj-contact {
        position: relative;
    }

    #contact-msg, #contact-form {
        width: 50%;
        padding: 1rem 2rem;
        height: inherit;
        display: inline-block;
    }

    #contact-msg {
        order: 1;
    }

    #contact-form {
        order: 2;

        form {

            label, input, textarea, button, image, span, a {
                position: relative; 
            }
    
            label {
                display: block;
                margin: 30px 0 10px;
            }
        
            input, textarea {
                font-size: 0.8rem;
                padding: 5px 10px;
                width: 70%;
                cursor: pointer;
            }
        
            textarea {
                height: 300px;
                resize: none;
            }
    
            button {
                display: block;
                margin-top: 30px;
                border: 1px solid inset grey;
                border-radius: 10px;
                padding: 7.5px 15px;
                cursor: pointer;
        
                img, span {
                    cursor: pointer;
                }
        
                img {
                    top: 2.5px;
                    width: 30px;
                }
        
                span {
                    top: -2.5px;
                    margin-left: 7.5px;
                    font-size: 0.95rem;
                }
            }
    
            a {
                display: block;
                font-size: 1.8rem;
                font-weight: 900 !important;
                text-decoration: none;
                cursor: pointer;
                margin: 3rem 0 0;
    
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    #dj-contact {
        order: 3;
            
        img {
            width: 160px;
            position: relative;
            left: -5px;
            top: 85%;
        }
    }
}

@media screen and (max-width: 1350px) {
    section#contact {
        h3 {
            left: 2%;
        }
    }
}


@media screen and (min-width: 1025px) and (max-width: 1199px) {
    section#contact {
        p {
            width: 85%;
        }

        #contact-form {
            form {
                input, textarea {
                    width: 85%;
                }
            }
        }      
    }
}

@media screen and (max-width: 1024px) {
    section#contact {
        flex-direction: column;

        #contact-msg, #contact-form {
            width: 100%;
            left: 0;
            padding: 0;     
        }
    
        p {
            width: 80%;
            left: 10%;
        }

        h3 {
            margin: 1.5rem 0 7rem;
            left: 30%;
        }

        #contact-form {
            form {

                input, textarea {
                    width: 80%;
                    left: 10%;
                }

                label, a {
                    left: 10%;
                }

                button {
                    left: 50%;
                    -webkit-transform: translateX(-50%);
                    -khtml-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                    transform: translateX(-50%);
                    margin-top: 50px;
                }                
            }
        }

        #dj-contact {
            width: 100%;
            left: 0;
            margin-top: 2rem;
            bottom: 0;

            img {
                left: 84%;
                top: 20px;
            }
        }
    }
}

@media screen and (max-width: 910px) {
    section#contact {
        #dj-contact {
            img {
                left: 80.5%;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    section#contact {
        h3 {
            left: 20%;
        }

        #dj-contact {
            right: 0;
            bottom: 0;

            img {
                width: 140px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                -khtml-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
            }
        }
    }
}

@media screen and (max-width: 675px) {
    section#contact {
        h3 {
            font-size: 2.5rem;
            left: 15%;
            margin-bottom: 5rem;
        }
    }
}

@media screen and (max-width: 500px) {
    section#contact {
        h3 {
            font-size: 2.2rem;
            left: 5%;
            margin-top: 3rem;
        }
        
        #contact-form {
            form {
                a {
                    font-size: 1.4rem;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    section#contact {

        p {
            width: 100%;
            left: 0;
            font-size: 0.85rem;
        }

        #contact-form {
            form {
                P, label, input, textarea, a {
                    width: 100%;
                    left: 0;
                }

                p, label {
                    font-size: 0.85rem;
                }
            }
        }
    }
}

@media screen and (max-width: 340px) {
    section#contact {  
        h3 {
            font-size: 2rem;
            left: 5%;
            margin-top: 3rem;
        }

        #contact-form { 
            form {
                button { 
                    img {
                        width: 22.5px;
                    }
        
                    span {
                        font-size: 0.7rem;
                    }
                }

                a {
                    font-size: 1.1rem;
                }
            }
        }
    }
}

/* IE10+ specific styles*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    #dj-contact {
        display: none;
    }

    #contact-form { 
        form {
            button { 
                width: 300px;
            }
        }
    }
}
