@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Architects+Daughter&family=Archivo+Black&family=Audiowide&family=Balsamiq+Sans&family=Gugi&family=Krona+One&family=Orbitron:wght@500&family=Rock+Salt&family=Shadows+Into+Light&family=Slackey&family=Syncopate&family=Beth+Ellen');

$headings: 'Syncopate', 'Audiowide', 'Orbitron', 'Slackey', 'Shadows Into Light', 'Rock Salt', 'Gugi', 'Balsamiq Sans', 'Archivo Black', 'Architects Daughter', 'Amatic SC', 'Helvetica', cursive, sans-serif;

$signature: 'Beth Ellen', cursive;

$black: rgb(0,0,0);
$black-mid: rgb(27, 32, 37);
$black-lightest: rgb(47, 51, 55);
$white: rgb(255,255,255);
$theme: rgb(255,0,0);
$theme2: rgb(82, 100, 117);