@import '../styles/settings.scss';

section#collection {
    display: block;
    position: relative;

    div, a {
        position: relative;
    }

    div {
        width: 100%;
    }
    
    a, p {
        color: $black-lightest;
    }

    a {
        cursor: pointer;
    }

    div:first-child {
        p {
            position: relative;
            width: auto;
            padding: 2rem 0 0 2rem;
        }
    }

    div:last-child {
        display: block;

        img {
            position: relative;
            display: block;
            width: 160px;
            bottom: -20px;
            left: 0;
        }

        p {
            position: absolute;
            width: auto;
            left: 50%;
            transform: translateX(-50%);
            padding-top: 2rem;
        }    
    }

    #collection-samples {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        width: 100%;

        iframe {
            position: relative;
            width: 300px;
            height: 300px;
            margin: 2rem 1.5rem 1rem;
            opacity: 0.75;
            box-shadow: 0 4px 7px 0 $theme2;

            &:hover {
                opacity: 1;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    section#collection {
        div:first-child {
            p {
                width: 80%;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    section#collection {
        div:first-child {
            p {
                padding-left: 0;
                width: 100%;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        div:last-child {
            img {
                width: 140px;
                left: 50%;
                transform: translateX(-50%);
            }

            p {
                position: relative;
                width: 100%;	
            }
        }
    }
}

@media screen and (max-width: 340px) {
    section#collection {
        #collection-samples {
            iframe {
                width: 250px;
                height: 250px;
            }
        }
    }
}

/* IE10+ specific styles*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    section#collection {
        div:last-child {
            img {
                display: none;
            }
        }

        #collection-samples {
            margin-bottom: 2rem;
        }
    }
}
