@import '../styles/settings.scss';

section#gallery {
    #gallery-images, #dj-gallery {
        position: relative;
    }

    #gallery-images {
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        margin-bottom: 1rem;
        margin-top: 2rem;

        img {
            width: 100%;
            max-width: 300px;
            height: 250px;
            border-radius: 3px;
            cursor: pointer;
            -webkit-transition: .3s; 
            -khtml-transition: .3s;
            -moz-transition: .3s;
            -ms-transition: .3s;
            -o-transition: .3s;
            transition: .3s;
            opacity: 0.6;
            margin: 1.5rem;
            box-shadow: 0 9px 10px -12px $white;

            &:hover {
                -webkit-transition: .3s; 
                -khtml-transition: .3s;
                -moz-transition: .3s;
                -ms-transition: .3s;
                -o-transition: .3s;
                transition: .3s;
                opacity: 1;
            }
        }
    }

    #dj-gallery {
        img {
            position: relative;
            width: 160px;
            left: calc(100% - 80px);
            bottom: -20px;
            -webkit-transform: translateX(-50%); 
            -khtml-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%); 
        }
    }
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000;
    padding-top: 100px; 
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; 
    overflow-y: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color (cross browser support for IE)*/
    background-color: rgba(0,0,0,0.925);
}

.modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
}

/* Zoom Animation */
.modal-content {  
    -webkit-animation-name: zoom;
    -webkit-animation-duration: .7s;
    animation-name: zoom;
    animation-duration: .7s;
}
  
@-webkit-keyframes zoom {
    from {-webkit-transform:scale(0)} 
    to {-webkit-transform:scale(1)}
}

@-moz-keyframes zoom {
    from {-webkit-transform:scale(0)} 
    to {-webkit-transform:scale(1)}
}

@keyframes zoom {
    from {transform:scale(0)} 
    to {transform:scale(1)}
}
  
.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #a1a1a1;
    font-size: 55px;
    font-weight: 500;
    transition: 0.3s;

    &:hover, &:focus {
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
    } 
}

@media screen and (max-width: 768px) {
    section#gallery {
        #dj-gallery {   
            img {
                width: 140px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .modal-content {
        width: 100%; /* 100% width on smaller screens */
    }
}
  

@media screen and (max-width: 425px) {
    section#gallery {
        #gallery-images {
            img {
                margin: 1.5rem 0;
            }
        }
        #dj-gallery {
            img {
                width: 150px;
            }
        }
    }
}

@media screen and (max-width: 350px) {
    section#gallery {
        #gallery-images {
            img {
                max-width: 250px;
                height: 200px;
            }
        }
    }
}

/* IE10+ specific styles*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    #dj-gallery {
        display: none;
    }
}