@import '../styles/settings.scss';

.current {
    color: $theme !important;
    font-style: oblique;
    border-bottom: 1px solid $theme;
}

.header {
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;
    position: fixed;
    width: 100vw;
    height: 100px;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%); 
    -khtml-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 999;
    background-image: linear-gradient(to right, #cecac5 0%,  #9c9996 25%, #405a6d 75%, #304352 100%);
    -webkit-transition: all .12s ease-out;
    -khtml-transition: all .12s ease-out;
    -moz-transition: all .12s ease-out;
    -ms-transition: all .12s ease-out;
    -o-transition: all .12s ease-out;
    transition: all .12s ease-out;
    overflow: hidden;    

    a, li, img {
        cursor: pointer;
    }

    span, a {
        font-family: $headings;
        font-weight: 700;
    }

    #logo {
        order: 1;
        display: inline;
        position: absolute;
        left: 10px;
        top: 50%;
        -webkit-transform: translateY(-50%); 
        -khtml-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);

        img {
            width: 150px;
            -webkit-transition: all .12s ease-out;
            -khtml-transition: all .12s ease-out;
            -moz-transition: all .12s ease-out;
            -ms-transition: all .12s ease-out;
            -o-transition: all .12s ease-out;
            transition: all .12s ease-out;
        }
    }

    #navbar {
        order: 2;
        display: inline;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -khtml-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        li {
            list-style: none;
            display: inline;
            margin: 0 15px;

            &:hover {
                font-style: oblique;
            }
        }

        a {
            text-decoration: none;
            color: $white;
            -webkit-transition: all .12s ease-out;
            -khtml-transition: all .12s ease-out;
            -moz-transition: all .12s ease-out;
            -ms-transition: all .12s ease-out;
            -o-transition: all .12s ease-out;
            transition: all .12s ease-out;
            font-family: $headings;
            font-size: 1.2rem;
        }
    }

    #telephone {
        order: 3;
        display: inline;
        position: absolute;
        right: 50px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -khtml-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);

        span, a {
            font-size: 1rem;  
            -webkit-transition: all .12s ease-out;
            -khtml-transition: all .12s ease-out;
            -moz-transition: all .12s ease-out;
            -ms-transition: all .12s ease-out;
            -o-transition: all .12s ease-out;
            transition: all .12s ease-out;
        }

        span {
            margin-right: 0.5rem;

            &:hover {
                text-decoration: none !important;
            }
        }

        a {
            text-decoration: none;
            color: $white;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    //Burger Menu -  don't display initially 
    .btn-burger {
        display: none;
        position: absolute;
        z-index: 5;
        right: 2rem;
        top: 50px;
        transform: translateY(-50%);
        cursor: pointer;
    
          .burger-btn-line {
               width: 28px;
               height: 3px;
               margin: 0 0 5px 0;
               background: #fff;
               -webkit-transition: all 0.35s ease-out;
               -ms-transition: all 0.35s ease-out;
               -moz-transition: all 0.35s ease-out;
               -o-transition: all 0.35s ease-out;
               transition: all 0.35s ease-out;
          } 
  
          //Rotate Menu Bar into X Close Sign
          &.close {
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            right: 2rem;
            top: 50px;
            transform: translateY(-50%);
  
            .burger-btn-line {
                //line 1 - rotate
                &:nth-child(1) {
                    -ms-transform: rotate(45deg) translate(6px, 6px);
                    transform: rotate(45deg) translate(6px, 6px);
                }
                // line 2 - hide
                &:nth-child(2) {
                    opacity: 0;
                }
                //line 1 - rotate
                &:nth-child(3) {
                    -ms-transform: rotate(-45deg) translate(4px, -6px);
                    transform: rotate(-45deg) translate(5px, -6px);
                }
            }
          }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ specific styles*/
    .current {
        color: $white !important;
        font-style: normal !important;
        border-bottom: none !important;
    }
}

@media screen and (max-width: 1199px) {
  .header {
      #logo {
          img {
            width: 150px;
          }
      }

      #navbar {
          a {
              font-size: 1rem;
          }
      }

      #telephone {
          right: 25px;
          
          span, a {
            font-size: 0.85rem;
          }
      }
  }
}

@media screen and (max-width: 1024px) {
    .header {
        height: 100px;
        -webkit-transition: height .3s ease;
        -khtml-transition: height .3s ease;
        -moz-transition: height .3s ease;
        -ms-transition: height .3s ease;
        -o-transition: height .3s ease;
        transition: height .3s ease;

        &.expanded {
            height: 380px;
            -webkit-transition: height .3s ease;
            -khtml-transition: height .3s ease;
            -moz-transition: height .3s ease;
            -ms-transition: height .3s ease;
            -o-transition: height .3s ease;
            transition: height .3s ease;
        }    

        #logo {
            top: 0;
            -webkit-transform: translateY(0); 
            -khtml-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0);

            img {
                width: 150px;
            }
        }
  
        #navbar {
            display: block;
            top: 115px;
            left: 20px;
            -webkit-transform: translateX(0);
            -khtml-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);

            li {
                display: block;
                padding: 15px 0;
            }
        }
  
        #telephone {
            display: none;
        }

        .btn-burger {
            display: block;
        }
    }
}