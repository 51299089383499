@import '../styles/settings.scss';

.hero-container {
	position: relative;
	width: 101vw;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-khtml-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	margin: 0;
	padding: 0;
	overflow: hidden;
	height: 100vh;
	top: 100px;
}

.hero-overlay {
	background: rgba(34, 34, 34, 0.65);
	position: absolute;
	width: 101vw;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	margin: 0;
	padding: 0;
	overflow: hidden;
	z-index: 9999;
	height: 100vh;

	h1 {
		font-family: $headings;
		font-size: 5.5rem;
		font-weight: 900;
		position: absolute;
		top: 40%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
        -khtml-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		text-align: center;

		i {
			font-weight: 100;
		}
	}
}

 figure#hero-collection {
	position: relative;
	width: 1000%;
	margin: 0;
	left: 0;
	-webkit-animation: slider 80s linear infinite 2.5s;
	-khtml-animation: slider 80s linear infinite 2.5s;
	-moz-animation: slider 80s linear infinite 2.5s;
	-o-animation: slider 80s linear infinite 2.5s;
	animation: slider 80s linear infinite 2.5s;

	img {
		width: 10%;
		height: 100vh;
		float: left;
	}
}

@-webkit-keyframes slider {
	0% { left: 0; }

	5% { left: -100%; }

	10% { left: -100%; }

	15% { left: -200%; }

	20% { left: -200%; }

	25% { left: -300%; }

	30% { left: -300%; }

	35% { left: -400%; }

	40% { left: -400%; }

	45% { left: -500%; }

	50% { left: -500%; }

	55% { left: -600%; }

	60% { left: -600%; }

	65% { left: -700%; }

	70% { left: -700%; }

	75% { left: -800%; }

	80% { left: -800%; }

	85% { left: -900%; }

	90% { left: -900%; }

	95% { left: -900%; }

	100% { left: -900%; }
}

@-moz-keyframes slider {
	0% { left: 0; }

	5% { left: -100%; }

	10% { left: -100%; }

	15% { left: -200%; }

	20% { left: -200%; }

	25% { left: -300%; }

	30% { left: -300%; }

	35% { left: -400%; }

	40% { left: -400%; }

	45% { left: -500%; }

	50% { left: -500%; }

	55% { left: -600%; }

	60% { left: -600%; }

	65% { left: -700%; }

	70% { left: -700%; }

	75% { left: -800%; }

	80% { left: -800%; }

	85% { left: -900%; }

	90% { left: -900%; }

	95% { left: -900%; }

	100% { left: -900%; }
}

@-o-keyframes slider {
	0% { left: 0; }

	5% { left: -100%; }

	10% { left: -100%; }

	15% { left: -200%; }

	20% { left: -200%; }

	25% { left: -300%; }

	30% { left: -300%; }

	35% { left: -400%; }

	40% { left: -400%; }

	45% { left: -500%; }

	50% { left: -500%; }

	55% { left: -600%; }

	60% { left: -600%; }

	65% { left: -700%; }

	70% { left: -700%; }

	75% { left: -800%; }

	80% { left: -800%; }

	85% { left: -900%; }

	90% { left: -900%; }

	95% { left: -900%; }

	100% { left: -900%; }
}

@keyframes slider {
	0% { left: 0; }

	5% { left: -100%; }

	10% { left: -100%; }

	15% { left: -200%; }

	20% { left: -200%; }

	25% { left: -300%; }

	30% { left: -300%; }

	35% { left: -400%; }

	40% { left: -400%; }

	45% { left: -500%; }

	50% { left: -500%; }

	55% { left: -600%; }

	60% { left: -600%; }

	65% { left: -700%; }

	70% { left: -700%; }

	75% { left: -800%; }

	80% { left: -800%; }

	85% { left: -900%; }

	90% { left: -900%; }

	95% { left: -900%; }

	100% { left: -900%; }
}

@media screen and (max-width: 768px) {
	.hero-overlay {
		h1 {
			font-size: 4.4rem;
		}
	}
	
	figure#hero-collection {
		img {
			height: 100vh;
		}
	}
}

@media screen and (max-width: 650px) {
	.hero-overlay {
		h1 {
			font-size: 3rem;
		}
	}
}

@media screen and (max-width: 425px) {
	.hero-overlay {
		h1 {
			font-size: 2.5rem;
		}
	}
}

@media screen and (max-width: 350px) {
	.hero-overlay {
		h1 {
			font-size: 2rem;
		}
	}
}

@media screen and (max-height: 600px) {
	.hero-overlay {
		h1 {
			top: 30%;
		}
	}
}

@media screen and (max-height: 425px) {
	.hero-overlay {
		h1 {
			top: 25%;
		}
	}
}

@media screen and (max-height: 375px) {
	.hero-overlay {
		h1 {
			top: 20%;
		}
	}
}

@media screen and (max-height: 330px) {
	.hero-overlay {
		h1 {
			top: 15%;
		}
	}
}