@import '../styles/settings.scss';


.footer {
    position: relative;
    width: 100vw;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 50px;
    background-image: linear-gradient(to right, #cecac5 0%,  #9c9996 25%, #405a6d 75%, #304352 100%);
    overflow: hidden;

    span {
        display: inline-block;
        font-family: $headings;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 0.8rem;
        color: $white !important;
        line-height: 1.5;
        text-align: center;
        width: inherit;
        padding: 10px 20px;
    }

    strong {
        font-style: oblique;
        font-weight: 900 !important;
        letter-spacing: 1px;
    }

    #social {
        position: absolute;
        right: 2%;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        
        #copy {
            color: $white;
        }

        a:nth-child(1), a:nth-child(2), a:nth-child(3) {
            margin-right: 20px;  
        }

        i {
            color: $white;
            font-size: 2.5rem;
            transition: all 0.25s ease-in-out;

            &:hover {
                color: red;
                cursor: pointer;
                transition: all 0.25s ease-in-out;
            }  
        }
    }
}

@media screen and (max-width: 1200px) {

    .footer {

        #social {
            i {
                font-size: 2.2rem;
            }
        }
    }
}

@media screen and (max-width: 1024px) {

    .footer {
        height: 100px;

        span {
            top: 75%;
            font-size: 0.7rem; 
        }

        #social {
            top: 15%;
            right: 50%;
            transform: translateX(50%);
        }
    }
}

@media screen and (max-width: 500px) {
    .footer {
        #social {
            top: 10%;
            width: 100%;
        }
    }
}