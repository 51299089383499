@import './settings.scss';

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    cursor: default;
}

html, body {
    font-family: $headings; 
    overflow-x: hidden;
    //chrome fix for scroll adding to overall body width
    overflow-y: overlay;

    //prevent user selection
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

html {
    //smooth scroll 
    -webkit-scroll-behavior: smooth;
    -khtml-scroll-behavior: smooth;
    -moz-scroll-behavior: smooth;
    -ms-scroll-behavior: smooth;
    -o-scroll-behavior: smooth;
    scroll-behavior: smooth;
}

body {
    background-image: linear-gradient(to right, #cecac5 0%,  #9c9996 25%, #405a6d 75%, #304352 100%);
}

.red {
    color: $theme;
}

.white {
    color: $white;
}

section {
    position: relative;
    width: 100vw;
    left: 50%;
    -webkit-transform: translateX(-50%); 
    -khtml-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 2rem 2rem 1.5rem;
}

a:active, a:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
    outline-style: none;
}

.section-dark {
    background: rgba(27, 32, 37, 1);
}

.section-light {
    background: rgba(245, 245, 245, 1);
}